import React, {useEffect} from 'react';

import styled from '@emotion/styled';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as UnauthorizedSvg} from '../assets/images/unauthorized.svg';
import {usePermissions} from '../hooks/usePermissions';

const LayoutStyled = styled.div`
    background-color: ${({theme}) => theme.palette.custom.background};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const TitleStyled = styled.div`
    margin-top: 50px;
    color: ${({theme}) => theme.palette.primary.main};
    font-weight: 800;
    font-size: 50px;
`;

const ContentStyled = styled.div`
    margin: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;
const ContentText = styled(Typography)`
    font-weight: 400;
    font-size: 20px;
`;

// endregion

export default function AccessDenied() {
    const {t} = useTranslation(['common']);
    const navigate = useNavigate();
    const {data} = usePermissions();

    useEffect(() => {
        if (data?.length) {
            navigate('/', {replace: true});
        }
    }, [data?.length, navigate]);

    return (
        <LayoutStyled>
            <UnauthorizedSvg />
            <TitleStyled>{t('app.accessDenied')}</TitleStyled>
            <ContentStyled>
                <ContentText>{t('app.invalidPermission')}</ContentText>
                <ContentText>{t('app.contactSupport')}</ContentText>
            </ContentStyled>
        </LayoutStyled>
    );
}
