import React, {createContext, useState, useEffect, useContext} from 'react';

import {useAuth0} from '@auth0/auth0-react';

const JwtContext = createContext(null as string | null);

export const useJwt = () => useContext(JwtContext);

interface ProviderProps {
    children: React.ReactNode;
}

const JwtProvider: React.FC<ProviderProps> = ({children}) => {
    const {getAccessTokenSilently, isAuthenticated} = useAuth0();
    const [jwt, setJwt] = useState<string | null>(null);

    useEffect(() => {
        isAuthenticated && getAccessTokenSilently().then((token) => setJwt(token));
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        if (!jwt) return;

        const jwtPayload = JSON.parse(window.atob(jwt.split('.')[1]));
        const expirationTime = jwtPayload.exp * 1000;
        const currentTime = Date.now();

        if (currentTime >= expirationTime) {
            getAccessTokenSilently().then((token) => token && setJwt(token));
        } else {
            const timeoutId = setTimeout(() => {
                getAccessTokenSilently().then((token) => token && setJwt(token));
            }, expirationTime - currentTime);

            return () => clearTimeout(timeoutId);
        }
    }, [jwt, getAccessTokenSilently]);

    return <JwtContext.Provider value={jwt}>{children}</JwtContext.Provider>;
};

export default JwtProvider;
