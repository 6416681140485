import {useAuth0} from '@auth0/auth0-react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {useJwt} from '../context/JwtContextProvider';
import {Api, Request} from '../services/auth/api';

export const usePermissions = () => {
    const {isAuthenticated} = useAuth0();
    const jwt = useJwt();
    const queryClient = useQueryClient();

    const key = getPermissionsKey(jwt);

    const func = () =>
        Request.get<string[]>(`${Api.getBaseUrl(Api.services.AUTH)}/permissions`).catch((e) => {
            if ([401, 403].includes(e.response?.status)) {
                queryClient.setQueryData(getPermissionsKey(jwt), e.response?.data ?? []);
            }
        });

    return useQuery<string[], AxiosError>({
        queryKey: key,
        queryFn: func,
        enabled: isAuthenticated && Boolean(jwt),
        gcTime: 15 * 60 * 1000,
        staleTime: 5 * 60 * 1000,
    });
};

export const getPermissionsKey = (jwt: string | null) => ['permissions', jwt];
