import React, {createRef, Suspense, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import {SnackbarProvider} from 'notistack';
import IconButton from '@mui/material/IconButton';
import ApplicationGlobalProvider from '../context/ApplicationGlobalContext';
import {dimensions} from '../constants/dimensions';
import LeftNavDrawer from './LeftNavDrawer';
import routes, {Login, Unauthorized} from './routes';
import {FullLoader} from './Loader';
import PrivateRoute from './auth/PrivateRoute';
import {usePermissions} from '../hooks/usePermissions';
import {useAuth0} from '@auth0/auth0-react';
import {usePrevious} from '../hooks/usePrevious';
import {Api} from '../services/auth/api';
import Header from './Header';
import AuthorizationContextProvider from '../context/AuthorizationContext';

// Styled Components
const AppContainer = styled('div')`
    display: flex;
    flex-direction: column;
    background-color: ${({theme}) => theme.palette.custom.background};
    height: 100vh;
    color: ${({theme}) => theme.palette.text.primary};
`;

const Content = styled('main')(({theme, open}) => ({
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: '100px',
    marginBottom: '20px',
    marginRight: '25px',
    flexGrow: 1,
    marginLeft: open ? dimensions.drawer.width.full : 0,
    overflowY: 'auto', // Ensure it scrolls if the content overflows
    [theme.breakpoints.down('sm')]: {
        marginLeft: open ? dimensions.drawer.width.mini : 0,
    },
}));

const MainContent = styled('div')`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    min-height: 100%; // Change min-height to height
`;

const HeaderStyled = styled('div')`
    position: sticky;
    top: 0;
`;

export default function App() {
    const navigate = useNavigate();
    const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
    const {data: permissions, isPending} = usePermissions(isAuthenticated);
    const previousPermissions = usePrevious(permissions);

    useEffect(() => {
        if (!isPending && !permissions?.length) {
            navigate('/unauthorized', {replace: true});
        }
    }, []);

    useEffect(() => {
        Api.init(getAccessTokenSilently, navigate);
    }, [getAccessTokenSilently, navigate]);

    const [open, setOpen] = useState(isAuthenticated && !permissions?.length);

    useEffect(() => {
        if (isAuthenticated && permissions?.length && !previousPermissions?.length) {
            setOpen(true);
        }
    }, [isAuthenticated, permissions, previousPermissions]);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const notistackRef = createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    if (isAuthenticated && isPending) {
        return <FullLoader />
    }

    const hasAuth = isAuthenticated && permissions?.length;

    const renderApp = (content) => {
        return (
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                action={(key) => (
                    <IconButton
                        color="inherit"
                        aria-label="dismiss"
                        onClick={onClickDismiss(key)}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                )}
                ref={notistackRef}
            >
                <ApplicationGlobalProvider>
                    <AppContainer>
                        <HeaderStyled>
                            <Header isDrawerOpen={hasAuth ? open : false} toggleDrawer={handleDrawerToggle} />
                        </HeaderStyled>
                        <AuthorizationContextProvider>
                            <Content open={hasAuth ? open : false}>
                                {hasAuth && (
                                    <LeftNavDrawer
                                        isDrawerOpen={open}
                                        toggleDrawer={handleDrawerToggle}
                                        permissions={permissions}
                                    />
                                )}
                                <MainContent>{content}</MainContent>
                            </Content>
                        </AuthorizationContextProvider>
                    </AppContainer>
                </ApplicationGlobalProvider>
            </SnackbarProvider>
        );
    };

    return (
        <Suspense fallback={<FullLoader />}>
            {renderApp(
                isLoading ? null : (
                    <Routes>
                        <Route path={Login.path} element={<Login.component />} />
                        {(hasAuth ? routes : []).map(({path, component, name}) => (
                            <Route
                                key={path}
                                path={path}
                                element={<PrivateRoute component={component} name={name} />}
                            />
                        ))}
                        <Route
                            key={Unauthorized.path}
                            path={Unauthorized.path}
                            element={
                                <PrivateRoute
                                    component={Unauthorized.component}
                                    name="Unauthorized"
                                />
                            }
                        />
                        <Route path="*" element={<Navigate to={Login.path} />} />
                    </Routes>
                )
            )}
        </Suspense>
    );
}
