import {useEffect, useRef} from 'react';

/**
 * Tracks the previous state of a value.
 * {@link https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state}
 *
 * @param value the props, state, or calculated value to track
 * @example
 * const Component = () => {
 *   const [count, setCount] = useState(0);
 *   const prevCount = usePrevious(count);
 *
 *   return `Now: ${count}, before: ${prevCount}`;
 * }
 *
 * @returns Value from the previous render of the enclosing component.
 */
export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}
