import React from 'react';
import styled from '@emotion/styled';
import carepathLogo from '../assets/images/CPlogo.png';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import {Alert} from '@mui/material';

// Styled Components
const LoginContent = styled('div')(({theme}) => ({
    width: '100%',
    fontSize: 45,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.palette.mode === 'dark' ? theme.palette.grey['300'] : theme.palette.grey['600'],
    marginTop: 85,
    textTransform: 'capitalize',
}));

const Logo = styled('img')({
    marginBottom: 20,
    width: '350px', // Set the width directly in the styled component
});

const Title = styled('div')(({theme}) => ({
    fontSize: 45,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.palette.mode === 'dark' ? theme.palette.grey['400'] : theme.palette.grey['600'],
    textTransform: 'uppercase',
    letterSpacing: 0.25,
}));

const ErrorAlert = styled(Alert)(({theme}) => ({
    width: '30%',
    margin: `${theme.spacing(3)} auto`,
}));

export default function Login() {
    const {t} = useTranslation('common');
    const {error} = useAuth0();

    return (
        <LoginContent>
            <Logo data-testid="logo" src={carepathLogo} alt="logo" />
            <Title>{t('app.title')}</Title>
            {error && (
                <ErrorAlert severity="error">
                    {`${error.name ?? 'Error'}: ${error.message}`}
                </ErrorAlert>
            )}
        </LoginContent>
    );
}
