import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useApplications} from '../services/auth/applications';

export const AuthorizationContext = createContext({
    selectedApp: '',
    setSelectedApp: (prev) => undefined,
});

export const useAuthorizationContext = () => useContext(AuthorizationContext);

export default function AuthorizationContextProvider({children}) {
    const [selectedApp, setSelectedApp] = useState();
    const {data: apps} = useApplications();

    useEffect(() => {
        if (apps && !selectedApp) {
            setSelectedApp(apps[0].id);
        }
    }, [apps, selectedApp]);

    return (
        <AuthorizationContext.Provider value={{selectedApp, setSelectedApp}}>
            {children}
        </AuthorizationContext.Provider>
    );
}

AuthorizationContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
