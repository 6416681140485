/** @jsxImportSource @emotion/react */
import {CircularProgress} from '@mui/material';
import styled from '@emotion/styled';

const LoadContainer = styled.div(({theme}) => ({
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.custom.background,
}));

const FullLoaderStyled = styled(CircularProgress)`
    position: relative;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
`;

export const FullLoader = () => {
    return (
        <LoadContainer>
            <FullLoaderStyled size={100} />
        </LoadContainer>
    );
};
