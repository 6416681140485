import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

const ApplicationGlobalContext = createContext({
    global: {},
});

export const useGlobalContext = () => {
    const {global} = useContext(ApplicationGlobalContext);
    return global;
};

export default function ApplicationGlobalProvider({children}) {
    const globalValue = useGlobalState();

    const contextValue = useMemo(
        () => ({
            global: globalValue,
        }),
        [globalValue]
    );

    return (
        <ApplicationGlobalContext.Provider value={contextValue}>
            {children}
        </ApplicationGlobalContext.Provider>
    );
}

const useGlobalState = () => {
    const navigate = useNavigate();
    const [isNavDisabled, setNavDisabled] = useState(false);
    const [crumbLinks, setCrumbLinks] = useState([]);

    const navigateToCrumb = useCallback(
        (idx) => {
            const reversed = [...crumbLinks].reverse();
            if (reversed[idx]) {
                navigate(reversed[idx].path);
            }
        },
        [crumbLinks, navigate]
    );

    return useMemo(
        () => ({
            navigateToCrumb,
            setCrumbLinks,
            isNavDisabled,
            setNavDisabled,
        }),
        [isNavDisabled, navigateToCrumb]
    );
};

ApplicationGlobalProvider.propTypes = {
    children: PropTypes.object.isRequired,
};
