import {GetTokenSilentlyOptions} from '@auth0/auth0-react';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {NavigateFunction} from 'react-router-dom';

import {Unauthorized} from '../../components/routes';

const axiosInstance = axios.create();
let _tokenFetcher: (options?: GetTokenSilentlyOptions) => Promise<string>;
let _navigate: NavigateFunction;

const ignoreList = ['/bootstrap/operational-portal'];

const init = (
    tokenFetcher: (options?: GetTokenSilentlyOptions) => Promise<string>,
    navigate: NavigateFunction
) => {
    _tokenFetcher = tokenFetcher;
    _navigate = navigate;

    // Add an interceptor to inject the token eagerly.
    axiosInstance.interceptors.request.use(
        async (config) => {
            if (ignoreList.some((path) => config.url?.endsWith(path))) {
                return config;
            }
            if (!_tokenFetcher) {
                return {
                    ...config,
                    cancelToken: new axios.CancelToken((cancel) =>
                        cancel(
                            'Axios API was not initialized with the appropriate token fetching function.'
                        )
                    ),
                };
            }

            try {
                const jwt = await _tokenFetcher();
                config.headers.authorization = `Bearer ${jwt}`;

                return config;
            } catch (e) {
                return {
                    ...config,
                    cancelToken: new axios.CancelToken((cancel) => cancel('No access token!')),
                };
            }
        },
        (error) => Promise.reject(error)
    );
};

const services = {
    AUTH: 'AUTH',
    SPI: 'SPI',
};

let serviceMap = new Map<string, string>();
const setServiceUrlMap = (map: Map<string, string>) => {
    serviceMap = map;
};

const getBaseUrl = (key: string) => serviceMap.get(key);

const _request = async <T>(config: AxiosRequestConfig, includeHeaders: boolean) => {
    if (!axiosInstance) {
        throw new Error('Axios must be initialized');
    }
    const onSuccess = (response: AxiosResponse) => (includeHeaders ? response : response.data);
    const onError = (error: AxiosError) => Promise.reject(error);

    try {
        const response = await axiosInstance<T>(config);
        return await onSuccess(response);
    } catch (error) {
        const _error = error as AxiosError;

        if (_error.config && [401, 403].includes(_error.response?.status as number)) {
            try {
                const newAccessToken = await _tokenFetcher({cacheMode: 'off'});

                const newConfig = {
                    ..._error.config,
                    headers: {
                        ..._error.config.headers,
                        Authorization: `Bearer ${newAccessToken}`,
                    },
                };

                return axiosInstance<T>(newConfig)
                    .then(onSuccess)
                    .catch((e) => {
                        if (window.location.pathname === Unauthorized.path) {
                            return onError(e);
                        } else {
                            _navigate(Unauthorized.path, {replace: true});
                        }
                    });
            } catch (tokenError) {
                return onError(tokenError as AxiosError);
            }
        }

        return await onError(_error);
    }
};

const get = <T>(url: string, options = {}, includeHeaders = false) =>
    _request<T>({method: 'get', url, ...options}, includeHeaders);
const post = <T>(url: string, data?: object, options = {}, includeHeaders = false) =>
    _request<T>({method: 'post', url, data, ...options}, includeHeaders);
const put = <T>(url: string, data?: object, options = {}, includeHeaders = false) =>
    _request<T>({method: 'put', url, data, ...options}, includeHeaders);
const patch = <T>(url: string, data?: object, options = {}, includeHeaders = false) =>
    _request<T>({method: 'patch', url, data, ...options}, includeHeaders);
const _delete = <T>(url: string, data?: object, options = {}, includeHeaders = false) =>
    _request<T>({method: 'delete', url, data, ...options}, includeHeaders);

export const Api = {
    getBaseUrl,
    init,
    setServiceUrlMap,
    services,
};

export const Request = {
    delete: _delete,
    get,
    post,
    put,
    patch,
};
