import {useQuery} from '@tanstack/react-query';

import {Request} from './api';
import {useLocalStorage} from '../../hooks/useStorage';

export const useBootstrap = () => {
    const key = ['bootstrap-configurations', process.env.REACT_APP_CONFIGURATION];

    const [persistedConfigs, setPersistedConfigs] = useLocalStorage(key.join('-'));

    const func = () => Request.get(process.env.REACT_APP_CONFIGURATION as string);
    const opts = {
        placeholderData: persistedConfigs,
        cacheTime: Infinity,
        staleTime: 1000 * 60 * 60, // 1 hour
        onSuccess: setPersistedConfigs,
    };
    return useQuery({
        queryKey: key,
        queryFn: func,
        ...opts,
    });
};
