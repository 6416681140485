import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import DarkModeIcon from '@mui/icons-material/Brightness4';
import LightModeIcon from '@mui/icons-material/Brightness7';
import {AppBar, Button} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ThemeContext, Themes} from '../context/ThemeContext';
import {useGlobalContext} from '../context/ApplicationGlobalContext';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import styled from '@emotion/styled';

const HeaderStyled = styled(AppBar)`
    height: 72px;
    padding: 0 12px;
    z-index: 2000; /* Ensure the header is above the drawer */
`;

const MenuButton = styled(IconButton)`
    transition: all 0.4s ease-in-out;

    &.open {
        transform: rotate(90deg);
    }
`;

const TitleContainer = styled.div`
    flex-grow: 1;
    display: flex;
`;

const PortalText = styled(Typography)`
    color: ${({theme}) => theme.palette.secondary.main};
    padding-left: 4px;
`;

const SignOutButton = styled(Button)`
    &.MuiButton-root {
        color: inherit;
    }
`;
const StyledContainer = styled.div`
    margin-left: auto;
    ${({theme}) => `${theme.breakpoints.down('md')} {
      margin-left: 0;
      width: 100%;
    }`}
    display: flex;
    height: 100%;
`;

export default function Header({isDrawerOpen, toggleDrawer}) {
    const {t} = useTranslation('common');
    const theme = useTheme();
    const {toggleDarkMode} = useContext(ThemeContext);
    const {isNavDisabled} = useGlobalContext();
    const {isAuthenticated, isLoading, loginWithRedirect, logout} = useAuth0();

    const icon = theme.palette.mode === Themes.LIGHT ? <LightModeIcon /> : <DarkModeIcon />;

    return (
        <HeaderStyled>
            <Toolbar>
                {!isAuthenticated ? (
                    <>
                        <TitleContainer>
                            <Typography variant="h6" noWrap>
                                {`${t('app.company')} - `}
                            </Typography>
                            <PortalText variant="h6" noWrap>
                                {t('app.title')}
                            </PortalText>
                        </TitleContainer>
                        {!isLoading && (
                            <SignOutButton
                                edge="end"
                                aria-label="logout"
                                color="inherit"
                                onClick={() => loginWithRedirect()}
                            >
                                {t('app.login')}
                            </SignOutButton>
                        )}
                    </>
                ) : (
                    <>
                        <MenuButton
                            color="inherit"
                            disabled={isNavDisabled}
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            edge="start"
                            className={clsx({open: isDrawerOpen})}
                            size="large"
                        >
                            <MenuIcon />
                        </MenuButton>
                        <TitleContainer>
                            <Typography variant="h6" noWrap>
                                {`${t('app.company')} - `}
                            </Typography>
                            <PortalText variant="h6" noWrap>
                                {t('app.title')}
                            </PortalText>
                        </TitleContainer>
                        <IconButton
                            edge="end"
                            aria-label="mode"
                            color="inherit"
                            onClick={toggleDarkMode}
                            size="large"
                        >
                            {icon}
                        </IconButton>
                        <SignOutButton
                            edge="end"
                            aria-label="logout"
                            color="inherit"
                            onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}
                        >
                            {t('app.logout')}
                        </SignOutButton>
                    </>
                )}
            </Toolbar>
        </HeaderStyled>
    );
}

Header.propTypes = {
    isDrawerOpen: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
};
