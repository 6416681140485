import {globalColors} from '../constants/colors';

const {primary, secondary, error, highlight, ...rest} = globalColors;

const LightTheme = {
    palette: {
        mode: 'light',
        text: {
            primary: '#47454C',
        },
        primary: {
            main: primary,
        },
        secondary: {
            main: secondary,
        },
        error: {
            main: error,
        },
        custom: {
            highlight: highlight,
            background: globalColors.white,
            gray: {
                1: '#F1F1F1',
                2: '#D3D4D5',
                3: '#7D7781',
                4: '#676767',
                5: '#505050',
                6: '#F6F7F8',
            },
            darkGray: {
                1: '#565757',
                2: '#47454C',
                3: '#040707',
            },
            ...rest,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 1150,
            md: 1500,
            lg: 2000,
            xl: 2900,
        },
    },
    overrides: {
        MuiTableCell: {
            body: {
                color: '#040707',
                fontWeight: 300,
            },
            head: {
                color: '#040707',
                fontWeight: 300,
            },
        },
    },
};

export default LightTheme;
