import {useAuth0} from '@auth0/auth0-react';
import {useQuery} from '@tanstack/react-query';

import {Api, Request} from './api';
import {Application} from '../../components/auth/types';

const APPLICATIONS_KEY = 'applications';

export const useApplications = () => {
    const {isAuthenticated} = useAuth0();
    const key = APPLICATIONS_KEY;
    const params = {sort: 'name'};
    const func = () => Request.get(`${Api.getBaseUrl(Api.services.AUTH)}/applications`, {params});

    return useQuery<Application[]>({
        queryKey: [key],
        queryFn: func,
        enabled: isAuthenticated,
    });
};
