import {globalColors} from '../constants/colors';
import {grey} from '@mui/material/colors';

const {primary, secondary, error, highlight, ...rest} = globalColors;

const DarkTheme = {
    palette: {
        mode: 'dark',
        text: {
            primary: grey['100'],
        },
        primary: {
            main: primary,
        },
        secondary: {
            main: secondary,
        },
        error: {
            main: error,
        },
        custom: {
            highlight: highlight,
            background: grey['800'],
            gray: {
                1: '#828282',
                2: '#676767',
                3: '#505050',
                4: '#4c4c4c',
                5: '#040707',
                6: '#F6F7F8',
            },
            darkGray: {
                1: '#565757',
                2: '#323232',
                3: '#f5f5f5',
            },
            ...rest,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 1150,
            md: 1500,
            lg: 2000,
            xl: 2900,
        },
    },
};

export default DarkTheme;
