export const globalColors = {
    text: '#7D7781',
    primary: '#0095C0',
    highlight: '#00BDF2',
    secondary: '#A3BB60',
    warn: '#FFA361',
    error: '#E21A2B',

    white: '#FFFFFF',
    gradient: 'linear-gradient(to right, #00bdf2 0%, #BBD532 100%)',

    allergy: '#D0443B',
    visit: '#A3BB60',
    lab: '#A3BB60',
    summary: '#00BDF2',

};
