import React, {ComponentType} from 'react';

import {withAuthenticationRequired} from '@auth0/auth0-react';

import {FullLoader} from '@cprx/react-common';

interface Props {
    component: ComponentType;
}

const PrivateRoute: React.FC<Props> = ({component}) => {
    // Wrap the component only with withAuthenticationRequired
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <FullLoader />, // Loading indicator while redirecting
    });

    return <Component />;
};

// Memoize to prevent HOC from returning a "new" component
export default React.memo(PrivateRoute);
