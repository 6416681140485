import {useCallback, useEffect, useState} from 'react';

const useStorage = <T extends object>(storage: Storage, key: string, initialValue?: T) => {
    const [storageValue, setStorageStorageValue] = useState<T | undefined>(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            const item = storage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            return initialValue;
        }
    });

    useEffect(() => {
        if (typeof window !== 'undefined' && key !== null && key !== undefined) {
            storage.setItem(key, JSON.stringify(storageValue));
        }
    }, [storageValue, setStorageStorageValue, key, storage]);

    const setValue = useCallback(
        (value: T | undefined) => {
            if (value === undefined) {
                setStorageStorageValue(value);
                return;
            }
            if (
                !storageValue ||
                JSON.stringify(value, Object.keys(value).sort()) !==
                    JSON.stringify(storageValue, Object.keys(storageValue).sort())
            ) {
                setStorageStorageValue(value);
            }
        },
        [storageValue]
    );

    if (key === null || key === undefined) {
        return [initialValue, () => null] as [T, (v: T) => void];
    }

    return [storageValue, setValue] as [T | undefined, (v: T) => void];
};

/**
 * Sync state to local storage so that it persists through a page refresh. Usage is similar to useState
 * except we pass in a storage key so that we can default to that value on page load instead of the
 * specified initial value.
 *
 * @param key the data key
 * @param initialValue the initial state value
 * @returns {[unknown, ((value: unknown) => void)]}
 */
export const useLocalStorage = <T extends object>(key: string, initialValue?: T) =>
    useStorage(localStorage, key, initialValue);

/**
 * Sync state to session storage so that it persists through a page refresh. Usage is similar to useState
 * except we pass in a storage key so that we can default to that value on page load instead of the
 * specified initial value.
 *
 * @param key the data key
 * @param initialValue the initial state value
 * @returns {[unknown, ((value: unknown) => void)]}
 */
export const useSessionStorage = <T extends object>(key: string, initialValue?: T) =>
    useStorage(sessionStorage, key, initialValue);
